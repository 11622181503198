<template>
    <div>
        <div @click="showModel">
            <slot></slot>   
        </div>
        <a-modal v-model:visible="show" title="选择数据" @cancel="show = false" :footer="null" width="900px">
            <div v-if="dataType == 'coupon'">
                <a-table size="small" :pagination="false" :data-source="list" row-key="id" :columns="[
                    {title:'ID',dataIndex:'id'},
                    {title:'优惠券名称',dataIndex:'coupon_name'},
                    {title:'最低消费金额',dataIndex:'low_cash_price'},
                    {title:'优惠金额',dataIndex:'coupon_price'},
                    {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
                ]" >
                    <template #action="{record}">
                        <a-button @click="selectPath(record)">选择</a-button>
                    </template>
                </a-table>
            </div>

            <div v-if="dataType =='goods'">
                 <a-table size="small" :scroll="{y: 400}" :pagination="false" :data-source="list" row-key="id" :columns="[
                    {title:'ID',dataIndex:'id'},
                    {title:'封面',dataIndex:'cover' ,slots:{customRender:'cover'}},
                    {title:'商品名称',dataIndex:'goods_name',width:400},
                    {title:'价格',dataIndex:'price'},
                    {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
                ]" :row-selection="mutil ? { selectedRowKeys: pks, onChange:(e)=>{pks = e} } :null">
                    <template #cover="{record}">
                        <a-image :src="record.cover" style="width:40px;height:40px"></a-image>
                    </template>
                    <template #action="{record}">
                        <a-button @click="selectPath(record)" v-if="!mutil">选择</a-button>
                    </template>
                </a-table>
            </div>
            <div v-if="dataType =='adopt'">
                 <a-table size="small" :scroll="{y: 400}" :pagination="false" :data-source="list" row-key="id" :columns="[
                    {title:'ID',dataIndex:'id'},
                    {title:'封面',dataIndex:'cover' ,slots:{customRender:'cover'}},
                    {title:'认养名称',dataIndex:'name',width:400,slots:{customRender:'name'}},
                    {title:'价格',dataIndex:'price'},
                    {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
                ]" :row-selection="mutil ? { selectedRowKeys: pks, onChange:(e)=>{pks = e} } :null">
                    <template #cover="{record}">
                        <a-image :src="record.cover" style="width:40px;height:40px"></a-image>
                    </template>
                    <template #name="{record}">
                        {{record.name}}
                        第{{record.numbers}}期
                    </template>
                    <template #action="{record}">
                        <a-button @click="selectPath(record)" v-if="!mutil">选择</a-button>
                    </template>
                </a-table>
            </div>
            <div v-if="dataType =='land'">
                <a-table size="small" :scroll="{y: 400}" :pagination="false" :data-source="list" row-key="id" :columns="[
                    {title:'ID',dataIndex:'id'},
                    {title:'封面',dataIndex:'cover',slots:{customRender:'cover'}},
                    {title:'土地名称',dataIndex:'land_name',width:'400px'},
                    {title:'最低价格',dataIndex:'price'},
                    {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
                ]" :row-selection="mutil ? { selectedRowKeys: pks, onChange:(e)=>{pks = e} } :null">
                    <template #cover="{record}">
                        <a-image :src="record.cover" style="width:40px;height:40px"></a-image>
                    </template>
                    <template #action="{record}">
                        <a-button @click="selectPath(record)" v-if="!mutil">选择</a-button>
                    </template>
                </a-table>
            </div>
            <div v-if="dataType =='information'">
                 <a-table size="small" :pagination="false" :data-source="list" row-key="id" :columns="[
                    {title:'ID',dataIndex:'id'},
                    {title:'封面',dataIndex:'cover' ,slots:{customRender:'cover'}},
                    {title:'标题',dataIndex:'title'},
                    {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
                ]" >
                    <template #cover="{record}">
                        <a-image :src="record.cover" style="width:40px;height:40px"></a-image>
                    </template>
                    <template #action="{record}">
                        <a-button @click="selectPath(record)">选择</a-button>
                    </template>
                </a-table>
            </div>
            <div v-if="dataType =='monitor'">
                 <a-table size="small" :pagination="false" :data-source="list" row-key="id" :columns="[
                    {title:'ID',dataIndex:'id'},
                    {title:'名称',dataIndex:'title'},
                    {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
                ]" >
                    <template #cover="{record}">
                        <a-image :src="record.cover" style="width:40px;height:40px"></a-image>
                    </template>
                    <template #action="{record}">
                        <a-button @click="selectPath(record)">选择</a-button>
                    </template>
                </a-table>
            </div>
            <div v-if="dataType =='seed'">
                <a-table size="small" :pagination="false" :data-source="list" row-key="id" :columns="[
                    {title:'ID',dataIndex:'id'},
                    {title:'种子封面',dataIndex:'cover',slots:{customRender:'cover'}},
                    {title:'种子名称',dataIndex:'send_name'},
                    {title:'操作',dataIndex:'action',slots:{customRender:'action'}},
                ]" :row-selection="mutil ? { selectedRowKeys: pks, onChange:(e)=>{pks = e} } :null">
                    <template #cover="{record}">
                        <a-image :src="record.cover" style="width:20px;height:20px"></a-image>
                    </template>
                    <template #action="{record}">
                        <a-button @click="selectPath(record)" v-if="!mutil">选择</a-button>
                    </template>
                </a-table>
            </div>

            <div class="pager mb12">
                <a-pagination
                    show-size-changer
                    :default-current="page"
                    :total="count"
                    @showSizeChange="(p,e)=>{getDataList(page,e)}"
                    @change="(e)=>{getDataList(e,limit)}"
                />
            </div>
            <a-space class="footer" v-if="mutil">
                <a-button @click="show = false">取消</a-button>
                <a-button type="primary" @click="selectPath(pks)">确定</a-button>
            </a-space>
        </a-modal>
    </div>
</template>
<script>
import { reactive, toRefs, watch } from 'vue'
import commonModel from '@/api/common'
import tool from "@/util/tool"
export default {
    name:'com-select-table-list',
    props:{
        dataType:{  //数据类型
            type:String,
            default:'goods'
        },
        dataIndex:{     //当前操作数据的下标
            type:Number,
            default:0
        },
        sku:{           //是否仅查询无规格的商品  0 查询无规格商品 1查询全部商品
            type:Number,
            default:1
        },
        mutil:{         //是否支持多选
            type:Boolean,
            default:false
        },
    },
    setup(props,context) {
        const datas = reactive({
            show:false,
            list:[],
            page:1,
            limit:10,
            count:0,
            pks:[],     //多选操作
        })
        function showModel(){
            datas.show = true
            getDataList(1,datas.limit)
        }

        //确认选择
        function selectPath(e){
            //多选
            if( props.mutil ){
                if( e.length == 0 ){
                    tool.message("请选择商品","warning")
                    return
                }
                let goods = []
                e.forEach((item) => {
                    let temp = datas.list.filter(val=>val.id == item)
                    goods.push(temp[0])
                });    
                datas.show = false
                context.emit("getData",{index:props.dataIndex,value:goods})
                return
            }
            datas.show = false
            context.emit("getData",{index:props.dataIndex,value:e})
        }

        const getDataList = (page,limit) => {
            let api = 'getGoodsList'
            let params ={
                page,
                limit
            }
            if( props.dataType == 'goods'){
                params.is_shop = 1
                params.audit = 1
                params.is_put_away = 1
                
                if( props.sku == 0 ){
                    params.is_open_sku = 0
                }
            }
            
            if( props.dataType == 'coupon' ) api = 'getCouponList'
            if( props.dataType == 'information') api = 'getArticleList'
            if( props.dataType == 'land') api = 'land2_getLandList'
            if( props.dataType == 'monitor' ) api = 'getLiveList'
            if( props.dataType == 'adopt') {
                api='getAdoptionList'
                params.is_chil = 1	//查询周期信息
            }
            if( props.dataType == 'seed') api="land2_getSeedList"
            if( datas.key !='' ) params.key = datas.keys
            commonModel.getDataList(api,params).then(res=>{
                datas.list = res.list
                datas.page = res.page
                datas.count = res.count
            })
        }

        return{
            ...toRefs(datas),
            getDataList,
            showModel,
            selectPath
        }
    },
}
</script>
<style scoped>
    .footer{
        display: flex;
        justify-content: flex-end;
    }
</style>