// 优惠券
import { $post } from '@/api/http.js'
import tool from '@/util/tool.js'
import common from "@/api/common.js"
import router from '@/router'
import moment from 'moment'
class Coupon{

    /**
     * 获取优惠券列表
     * @param {number} page 
     * @param {number} limit 
     * @param {object} extra 
     * @param {function} fn 
     */
    getCoupon(page,limit,extra,fn){
        let param = {page,limit}
        if( extra ){
            if( extra.key ) param.key = extra.key 
            if( extra.type ) param.type = extra.type 
        }
        common.getDataList("getCouponList",param).then(res=>fn(res))
    }

    /**
     * 获取优惠券详情
     * @param {number} id 
     * @param {Array} keys 
     * @param {function} fn 
     */
    getCouponDetail(id,keys,fn){
        $post('getCouponDetail',{id}).then(res=>{
            if( keys ){
                fn(tool.filterForm(keys,res.data))
                return
            }
            fn(res.data)
        },()=>{})
    }

    /**
     * 新增或编辑优惠券信息
     * @param {object} param 
     */
    addOrEditCoupon(param){
        if( !tool.returnMessage(param.coupon_name,'请填写优惠券名称')) return
        if( !tool.returnMessage(param.coupon_price,'请填写优惠价金额')) return
        if( !tool.returnMessage(param.type,'请选择优惠券类型')) return
        if( !tool.returnMessage(param.low_cash_price,'请填写最低消费金额')) return

        if( !param.id ) delete param.id
        if( param.is_specify == 1 ) param.specify_goods =[]
        if( param.begin_time ) param.begin_time = moment(param.begin_time).format("YYYY-MM-DD HH:mm:ss")
        if( param.end_time ) param.end_time = moment(param.end_time).format("YYYY-MM-DD HH:mm:ss")
        $post('createOrUpdateCoupon',param).then(()=>{
            tool.message("保存成功")
            router.go(-1)
        })
    }
}
const couponModel = new Coupon()
export default couponModel